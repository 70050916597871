<template>
  <div class="index-contain">
    <div class="section">
      <div class="title">公众消防服务预约</div>
      <ul class="list">
        <li class="list-item" v-for="(item,index) in bookList" :key="index" @click="onhandleJump(item)">
          <i :class="['icon', `icon-${item.icon}`]"></i>
          <div class="name">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="section">
      <div class="title">隐患举报及智能问答</div>
      <ul class="list">
        <li class="list-item" v-for="(item,index) in list" :key="index" @click="onhandleJump(item)">
          <i :class="['icon', `icon-${item.icon}`]"></i>
          <div class="name">{{item.name}}</div>
        </li>
      </ul>
    </div>
    <div class="section">
      <div class="title">消防知识学习</div>
      <ul class="list">
        <li class="list-item" v-for="(item,index) in studylist" :key="index" @click="onhandleJump(item)">
          <i :class="['icon', `icon-${item.icon}`]"></i>
          <div class="name">{{item.name}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      bookList: [{
        icon: '03',
        name: '提前复查预约'
      },{
        icon: '04',
        name: '消防安全培训预约'
      },{
        icon: '05',
        name: '火灾防控指导预约'
      },{
        icon: '06',
        name: '大型活动安保预约'
      },{
        icon: '07',
        name: '消防监督检查预约'
      },
      // {
      //   icon: '07',
      //   name: '营业前消防安全检查预约'
      // }
    ],
      list: [
      // {
      //   icon: '08',
      //   name: '火灾隐患举报'
      // },
      {
        icon: '09',
        name: '智能问答'
      }],
      studylist: [{
        icon: '10',
        name: '学习平台'
      },{
        icon: '11',
        name: '消防资讯'
      }]
    };
  },
  mounted() {
  },
  methods: {
    onhandleJump(item) {
      let path
      if(item.name === '火灾隐患举报'){
        path = '/firereport'
      } else if(item.name === '智能问答') {
        path = '/message'
      } else if(item.name === '学习平台') {
        path = '/studyIndex'
      } else if(item.name === '消防资讯') {
        path = '/fireInfoModelIndex'
      }else if(item.name === '营业前消防安全检查预约') {
				path = '/businessIndex'
      }else{
				path = '/bookingIndex'
			}
      this.$router.push({
        path,
        query: {
          name: item.name
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
  .index-contain{
    .section{
      padding: 0 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.39);
      background-color: #fff;
      .title{
        position: relative;
        padding: 0 0 0 13px;
        margin: 12px 0 6px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 2px;
          height: 14px;
          background-color: #D41312;
        }
      }
      .list{
        display: flex;
        // justify-content: space-around;
        flex-wrap: wrap;
        padding-bottom: 20px;
        .list-item{
          width: 33%;
          text-align: center;
          margin: 20px 0 0;
          .icon{
            display: block;
            margin: auto;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            &.icon-01{
              background-image: url('../assets/images/icon_index_01.png');
            }
            &.icon-02{
              background-image: url('../assets/images/icon_index_02.png');
            }
            &.icon-03{
              background-image: url('../assets/images/icon_index_03.png');
            }
            &.icon-04{
              background-image: url('../assets/images/icon_index_04.png');
            }
            &.icon-05{
              background-image: url('../assets/images/icon_index_05.png');
            }
            &.icon-06{
              background-image: url('../assets/images/icon_index_06.png');
            }
            &.icon-07{
              background-image: url('../assets/images/icon_index_07.png');
            }
            &.icon-08{
              background-image: url('../assets/images/icon_index_08.png');
            }
            &.icon-09{
              background-image: url('../assets/images/icon_index_09.png');
            }
            &.icon-10{
              background-image: url('../assets/images/icon_index_10.png');
            }
            &.icon-11{
              background-image: url('../assets/images/icon_index_11.png');
            }
          }
          .name{
            padding-top: 5px;
            font-size: 12px;
            color: #333333;
          }
        }
      }
    }
  }
</style>
